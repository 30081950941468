import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout/Layout";
import PageHero from "../components/Hero/PageHero";
import SEO from "../components/SEO/SEO";
import SliceZone from "../components/SliceZone/SliceZone";


export const query = graphql`
  query Product($uid: String) {
    allPrismicProduct(
      filter: {
        uid: {
          eq: $uid
        }
      }
    ) {
      edges {
        node {
          data {
            meta_title {
              text
            }
            meta_description {
              text
            }
            product_description {
              text
            }
            product_page_title {
              text
            }
            body {
              ... on PrismicProductBodyProductVariant {
                items {
                  product_variant_description {
                    text
                  }
                  product_variant_image {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 700
                          quality: 80
                          placeholder: TRACED_SVG
                          formats: [AUTO, WEBP, AVIF]
                        )
                      }
                    }
                  }
                  product_variant_title {
                    text
                  }
                }
                primary {
                  text_color
                  schedule_link {
                    url
                    link_type
                  }
                  schedule_link_text {
                    text
                  }
                  phone_number_text {
                    text
                  }
                  phone_number {
                    url
                    link_type
                  }
                }
                slice_type
              }
              ... on PrismicProductBodyTestimonials {
                items {
                  testimonial_author {
                    text
                  }
                  testimonial_text {
                    text
                  }
                  testimonial_rating {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 200
                          quality: 80
                          placeholder: TRACED_SVG
                          formats: [PNG]
                        )
                      }
                    }
                  }
                }
                primary {
                  section_title {
                    text
                  }
                  section_description {
                    text
                  }
                  background_color
                  text_color
                }
                slice_type
              }
              ... on PrismicProductBodyCallToAction {
                primary {
                  text_color
                  background_color
                  action_text {
                    text
                  }
                  action_number {
                    text
                  }
                  action_link {
                    url
                    link_type
                  }
                }
                slice_type
              }
              ... on PrismicProductBodyText {
                primary {
                  text_color
                  section_title {
                    text
                  }
                  section_page_link_text {
                    text
                  }
                  section_page_link {
                    url
                    link_type
                  }
                  section_icon {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                  section_description {
                    text
                  }
                  icon_color
                  background_color
                }
                slice_type
              }
              ... on PrismicProductBodyProcess {
                items {
                  process_stage_title {
                    text
                  }
                  process_stage_icon {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 120
                          quality: 100
                          placeholder: TRACED_SVG
                          formats: [PNG]
                        )
                      }
                    }
                  }
                  process_stage_description {
                    text
                  }
                }
                primary {
                  background_color
                  icon_color
                  section_description {
                    text
                  }
                  section_title {
                    text
                  }
                  text_color
                }
                slice_type
              }
              ... on PrismicProductBodyBenefits {
                items {
                  benefit_item {
                    text
                  }
                }
                primary {
                  text_color
                }
                slice_type
              }
              ... on PrismicProductBodyRelatedProducts {
                id
                items {
                  overlay_color
                  related_product {
                    url
                    link_type
                    document {
                      ... on PrismicProduct {
                        data {
                          product_related_title {
                            text
                          }
                          product_title {
                            text
                          }
                          related_product_image {
                            localFile {
                              childImageSharp {
                                gatsbyImageData(
                                  width: 700
                                  quality: 80
                                  placeholder: BLURRED
                                  formats: [AUTO, WEBP, AVIF]
                                )
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  text_color
                }
                primary {
                  section_title {
                    text
                  }
                  section_description {
                    text
                  }
                  background_color
                  text_color
                }
                slice_type
              }
            }
          }
        }
      }
    }
  }
`;


const ProductTemplate = ({
  data
}) => {
  if (!data) return null;

  const document = data.allPrismicProduct.edges[0].node.data;

  const productMeta = {
    metaTitle: document.meta_title.text,
    metaDescription: document.meta_description.text,
  };

  const productContent = {
    productTitle: document.product_page_title.text,
    productDescription: document.product_description.text,
  };

  return (
    <Layout
      isLandingPage={ false }
      headerTextColor="#231F20"
    >
      <SEO
        title={ productMeta.metaTitle }
        description={ productMeta.metaDescription }
      />

      <PageHero
        pageTitle={ productContent.productTitle }
        pageDescription={ productContent.productDescription }
      />

      <SliceZone sliceZone={ document.body } />
    </Layout>
  )
}


export default ProductTemplate;
